import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropertyDashboard from './Components/PropertyDashboard';
import PropertyDetails from './Components/PropertyDetails';
import { apiGetPropertyAnalytics } from '../../../api/property';
import Error404 from '../../pages/Error404';
import { apiGetPremiumValue } from '../../../api/premiumvalue';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import TablesByDistrictData from './Components/TablesByDistrictData';
import { searchSuperIndicesData } from '../../../api/superindexes';
import KeyPerformanceMetricsTable from '../Dashboard/Tables/KeyPerformanceMetricsTable';
import { formatKeyPerformanceObject, propertySVGs } from '../../../utils/helpers';
import { apiGetRiskFreeRate } from '../../../api/avm';
import PriceChart from './Components/PriceChart';
import RentalCharts from './Components/RentalCharts';
import { getTargetTime } from '../../../api/propertyIndex';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { ReactComponent as Info } from 'icons/custom/info-icon.svg';
import { ReactComponent as Refresh } from 'icons/custom/refresh.svg';

import SecondaryButton from '../Dashboard/Buttons/SecondaryButton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PropertyPdf from './PdfComponents/PropertyPdf';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getPropertyAppraisal } from '../../../api/appraisal';
import { getDemographicsByDistrictCode } from '../../../api/demographics';
import { generatePropertyComparables, getComparablesByIds, getPropertyComparables } from '../../../api/comparables';
import { getPropertyListings } from '../../../api/listings';
import moment from 'moment';
import axios from 'axios';
import StatusModal from '../Dashboard/Modals/StatusModal';

const Property = () => {
  const { propertyId } = useParams();

  const [searchTableData, setSearchTableData] = useState([]);
  const [benchmarkDropdown, setBenchmarkDropDown] = useState([]);
  const [propertyPM, setPropertyPM] = useState({});
  const [benchmarkPM, setBenchmarkPM] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [shouldRenderPdf, setShouldRenderPdf] = useState(false);
  const [priceChartImg, setPriceChartImg] = useState();
  const [rentPriceChartImg, setRentPriceChartImg] = useState();
  const [rentYieldChartImg, setRentYieldChartImg] = useState();
  const [demographicsData, setDemographicsData] = useState(null);

  const [appraisalData, setAppraisalData] = useState(undefined);

  const [pdfReady, setPdfReady] = useState(false);
  const linkRef = useRef(null);
  const queryClient = useQueryClient();

  const [statusModalContent, setStatusModalContent] = useState({
    type: '',
    description: '',
  });

  const { data: targetTime } = useQuery(['targetTime'], ({ signal }) => getTargetTime(signal));
  const { data: riskFreeRate = undefined } = useQuery(['riskFreeRate'], ({ signal }) => apiGetRiskFreeRate(signal));

  const {
    data: property,
    refetch: refetchProperty,
    error,
  } = useQuery(['property-analytics', propertyId], ({ signal }) => apiGetPropertyAnalytics(propertyId, signal), {
    refetchIntervalInBackground: true,
    refetchInterval: (data) => {
      if (data?.status !== 'In progress' && data?.status !== 'Comparables In progress') {
        queryClient.invalidateQueries(['comparables', data?.id, data?.status]);
        // queryClient.invalidateQueries(['listings', data?.id]);
        return false;
      }
      return 30000;
    },
    onSuccess: () => {
      if (property?.status === 'Completed' && property?.property_index_data) {
        queryClient.invalidateQueries(['comparables', property?.id, property?.status]);
        // queryClient.invalidateQueries(['listings', property?.id]);
      }
    },
  });

  const { data: comparablesData, isLoading: comparablesLoading } = useQuery(
    ['comparables', property?.id, property?.status],
    ({ signal }) =>
      getPropertyComparables(
        property?.id,
        {
          max_price: property?.price ? Math.round(property?.price * 1.3) : 10000000,
          min_price: property?.price ? Math.round(property?.price * 0.7) : 0,
          max_size: null,
          null_toggle: true,
          offset: 0,
          old_new_lr: null,
          filtered_ids: [],
          bathrooms: [],
          current_energy_rating_epc: [],
          distance_from: null,
          freehold_leasehold: null,
          limit: 10,
          min_size: null,
          order_by: 'date',
          ascending: false,
        },
        signal,
      ),
    {
      enabled: !!property?.property_index_data && property?.status === 'Completed',
    },
  );

  const [minMultiplier, setMinMultiplier] = useState(0.9);
  const [maxMultiplier, setMaxMultiplier] = useState(1.1);

  const [callCount, setCallCount] = useState(0);

  const { data: optimalComparablesData, isSuccess: optimalComparablesLoaded } = useQuery(
    ['comparables', property?.id, 'optimal', property?.price, minMultiplier, maxMultiplier],
    ({ signal }) =>
      property?.comp_ids?.length > 0
        ? getComparablesByIds(property?.comp_ids, signal)
        : getPropertyComparables(
            property?.id,
            {
              order_by: 'distance_from',
              dataset: 'LR',
              ascending: true,
              max_price: property?.price ? Math.round(property?.price * maxMultiplier) : 10000000,
              min_price: property?.price ? Math.round(property?.price * minMultiplier) : 0,
              offset: 0,
              limit: 10,
            },
            signal,
          ),
    {
      enabled: !!property?.price && callCount < 10,
    },
  );

  useEffect(() => {
    if (property?.comp_ids?.length > 0 && optimalComparablesLoaded) {
      setCallCount(10);
    } else if (optimalComparablesLoaded && callCount < 10) {
      const distinctComparables = optimalComparablesData?.data.filter(
        (v, i, a) => a.findIndex((t) => t.address === v.address) === i,
      );

      if (distinctComparables.length < 5) {
        setMinMultiplier((prev) => Math.max(0, prev - 0.1));
        setMaxMultiplier((prev) => prev + 0.1);
        setCallCount((prev) => prev + 1);
      }
    }
  }, [optimalComparablesLoaded, optimalComparablesData, callCount, property?.comp_ids?.length]);

  const optimalComparablesResult = useMemo(() => {
    if (optimalComparablesLoaded) {
      let comparables = optimalComparablesData?.data || [];

      if (!property?.comp_ids?.length) {
        comparables = comparables.filter((v, i, a) => a.findIndex((t) => t.address === v.address) === i);

        comparables.sort((a, b) => {
          const mainPropertyPrice = property?.price || 0;
          return Math.abs(a.price - mainPropertyPrice) - Math.abs(b.price - mainPropertyPrice);
        });
      }

      comparables = comparables.slice(0, 5);

      return comparables || {};
    } else {
      return {};
    }
  }, [optimalComparablesLoaded, optimalComparablesData?.data, property?.comp_ids?.length, property?.price]);

  const { data: listingsData } = useQuery(
    ['listings', property?.id],
    ({ signal }) =>
      getPropertyListings(
        {
          latitude: property?.latitude,
          longitude: property?.longitude,
          ascending: false,
          order_by: 'address',
          max_distance: 10,
          min_distance: 0,
          district_code: property?.property_index_data?.district_code,
          post_town: property?.property_index_data?.post_town,
          number_of_bedrooms: [parseInt(property?.property_index_data?.number_of_bedrooms)],
          property_type: [property?.property_index_data?.property_type],
          offset: 0,
          limit: 10,
          min_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        },
        signal,
      ),
    {
      enabled: !!property?.property_index_data && property?.status === 'Completed',
    },
  );

  const { data: premiumValue, isLoading: premiumValueLoading } = useQuery({
    queryKey: [
      'premiumValue',
      `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
    ],
    queryFn: ({ signal }) =>
      apiGetPremiumValue(
        `${property?.property_index_data?.post_town} ${property?.property_index_data?.district_code} ${property?.property_index_data?.property_type} ${property?.property_index_data?.number_of_bedrooms}`,
        signal,
      ),
    enabled: !!property?.property_index_data,
  });

  const handleDownloadClick = () => {
    setShouldRenderPdf(true);
    setPdfReady(false);
  };

  useEffect(() => {
    if (pdfReady && linkRef.current) {
      linkRef.current.click();
      setShouldRenderPdf(false);
    }
  }, [pdfReady]);

  const getMetricParameters = async (propertyData) => {
    try {
      const propertyKeyPerformance = formatKeyPerformanceObject(
        propertyData?.property_index_data,
        riskFreeRate,
        targetTime,
      );
      setPropertyPM(propertyKeyPerformance);
      getBenchmarkData(propertyData.property_index_data);

      const appraisalData = await getPropertyAppraisal(propertyData.id);
      setAppraisalData(appraisalData);
    } catch (error) {
      console.error(error);
    }
  };

  const getBenchmarkData = async (propertyIndexData) => {
    try {
      const searchResponse = await searchSuperIndicesData({
        offset: 0,
        limit: 50,
        district_exact: propertyIndexData.district_code,
        order_by: 'Chg_5Y',
        ascending: false,
      });

      const searchData = searchResponse?.data?.data;
      setSearchTableData(searchData);

      const dropdownData = searchData.map((searchResult) => ({
        label: `${searchResult.post_town},
                District: ${searchResult.district},
                Type ${searchResult.property_type},
                Rooms: ${searchResult.number_of_bedrooms}`,
        value: searchResult.super_index_name,
        id: searchResult.index,
        ...searchResult,
      }));

      const identifier = `${propertyIndexData.post_town} ${propertyIndexData.district_code} ${propertyIndexData.property_type} ${propertyIndexData.number_of_bedrooms}`;
      const selectedOption = dropdownData.find((item) => item.value === identifier);
      const benchmarkKeyPerformance = selectedOption
        ? formatKeyPerformanceObject(selectedOption, riskFreeRate, targetTime)
        : [];
      setSelectedIndex(selectedOption);
      setBenchmarkPM(benchmarkKeyPerformance);
      dropdownData.sort((a, b) => a.label.localeCompare(b.label));
      setBenchmarkDropDown(dropdownData);
    } catch (error) {
      console.error(error);
    }
  };

  const updateBenchmarkSelection = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
  };

  const getDemographicsByProperty = async (property) => {
    try {
      const demographicsData = await getDemographicsByDistrictCode(property?.property_index_data?.district_code);
      setDemographicsData({
        demographics: demographicsData,
        district_code: property?.property_index_data?.district_code,
      });
    } catch (e) {
      console.error(e);
    }
  };

  let cancelToken;
  const generateComparables = async () => {
    if (cancelToken) {
      cancelToken.cancel('Operation cancelled due to new Request');
    }
    cancelToken = axios.CancelToken.source();

    try {
      await generatePropertyComparables({ ...property, num_rooms: Number(property.num_rooms) }, cancelToken);

      setStatusModalContent({
        show: true,
        type: 'success',
        description: `Comparables data for this property is now being refreshed.
        This process normally takes 1-2 minutes but may sometimes take a little longer.`,
      });

      refetchProperty();
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  useEffect(() => {
    if (property?.property_index_data && property.status !== 'In progress') {
      getMetricParameters(property);
      getDemographicsByProperty(property);
    }
  }, [property, riskFreeRate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <Error404 />;
  }

  if (property?.status === 'In progress') {
    return (
      <div
        className="w-full h-screen	m-auto flex flex-column justify-center items-center bg-white rounded-2xl"
        style={{ height: 'calc(100vh - 215px)' }}
      >
        <LoaderSvg className="animate-spin h-10 w-10 text-white" />
        <div className="text-3xl font-medium mt-14" style={{ color: '#3571E5' }}>
          Valuation in Progress
        </div>
      </div>
    );
  }

  if (property && property?.status !== 'In progress') {
    return (
      <>
        <StatusModal
          setShowModal={() => setStatusModalContent({ show: false })}
          showModal={statusModalContent.show}
          content={statusModalContent}
        />
        <div className="w-full flex items-center justify-between mb-4">
          <div className="flex flex-wrap items-center justify-start">
            {property.address && <div className="text-black text-2xl font-medium mr-2">{property.address}</div>}
            {property.property_index_data ? (
              <>
                <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                  District: {property.property_index_data?.district_code}
                </div>
                <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                  Type: {propertySVGs[property.property_index_data?.property_type]?.name}
                </div>
                <div className="bg-white rounded-2xl py-1 px-2 ml-1" style={{ color: '#7B8FB7' }}>
                  Rooms: {property.property_index_data?.number_of_bedrooms}
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="flex">
            {property?.parent_id ? (
              <div
                style={{ backgroundColor: '#E1EAFB', fontSize: 12 }}
                className="px-2 py-2 rounded-lg flex items-center justify-start"
              >
                <div className="flex items-center justify-start">
                  <Info className="w-5 mr-1" />
                  This property has been {property?.changed_number_of_bedrooms ? 'cloned' : 'revalued'}
                </div>
                <Link
                  className="underline pl-3"
                  to={`/property-analytics/${property?.parent_id}`}
                  style={{ color: '#3571E5' }}
                >
                  See original property
                </Link>
              </div>
            ) : (
              ''
            )}

            <div>
              <SecondaryButton
                className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap"
                onClick={generateComparables}
              >
                <Refresh className="h-5 w-5 text-white mr-2" />
                Refresh Valuation 
              </SecondaryButton>
            </div>

            <div>
              {shouldRenderPdf ? (
                <PDFDownloadLink
                  document={
                    <PropertyPdf
                      property={property}
                      priceChartImg={priceChartImg}
                      rentPriceChartImg={rentPriceChartImg}
                      rentYieldChartImg={rentYieldChartImg}
                      premiumValue={premiumValue}
                      itemPM={propertyPM}
                      benchmarkPM={benchmarkPM}
                      demographicsData={demographicsData}
                      comparablesData={comparablesData}
                      optimalComparablesData={optimalComparablesResult}
                      listingsData={listingsData}
                      searchTableData={searchTableData}
                    />
                  }
                  fileName="PropertyDetails.pdf"
                >
                  {({ url, loading }) => {
                    if (!loading && url) {
                      setPdfReady(true);
                    }
                    return loading ? (
                      <div className="flex items-center h-10">
                        <LoaderSvg className="animate-spin h-6 w-6 text-white mr-2" />
                      </div>
                    ) : (
                      <div ref={linkRef}>
                        <SecondaryButton className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap">
                          Download PDF
                        </SecondaryButton>
                      </div>
                    );
                  }}
                </PDFDownloadLink>
              ) : (
                <SecondaryButton
                  className="px-3 ml-4 min-w-44 w-auto bg-white whitespace-nowrap"
                  onClick={handleDownloadClick}
                >
                  Download PDF
                </SecondaryButton>
              )}
            </div>
          </div>
        </div>
        <PropertyDashboard
          property={property}
          premiumValue={premiumValue}
          premiumValueLoading={premiumValueLoading}
          generateComparables={() => generateComparables()}
          comparablesData={comparablesData}
          comparablesLoading={comparablesLoading}
        />
        <PropertyDetails property={property} demographicsData={demographicsData} />
        <PriceChart
          property={property}
          benchmarkDropdown={benchmarkDropdown}
          selectedBenchmark={selectedIndex}
          updateBenchmarkSelection={(selectedBenchmark) => updateBenchmarkSelection(selectedBenchmark)}
          targetTime={targetTime}
          renderImage={setPriceChartImg}
          extendedData={appraisalData}
        />
        <RentalCharts
          property={property}
          selectedBenchmark={selectedIndex}
          targetTime={targetTime}
          updateProperty={() => refetchProperty()}
          setRentPriceChartImg={setRentPriceChartImg}
          setRentYieldChartImg={setRentYieldChartImg}
        />

        <KeyPerformanceMetricsTable itemPM={propertyPM} benchmarkPM={benchmarkPM}></KeyPerformanceMetricsTable>
        <TablesByDistrictData property={property} searchTableData={searchTableData} />
      </>
    );
  }
};

export default Property;
