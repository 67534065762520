import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
import PropertyCard from './PropertyCard';
import { useEffect, useState } from 'react';
import PrimaryButton from '../../Dashboard/Buttons/PrimaryButton';
import StatusModal from '../../Dashboard/Modals/StatusModal';
import PropertyGenerationStatusModal from '../../Dashboard/Components/PropertyGenerationStatusModal';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';
import { getCoordinates } from '../../../../api/search';
import { createProperty } from '../../../../api/property';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SecondaryButton from '../../Dashboard/Buttons/SecondaryButton';
import _ from 'lodash';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const OptimisedCompsList = ({
  property,
  optimalComparables,
  optimalComparablesResult,
  toggleFavourite,
  resetComparables,
}) => {
  const [valuationInProgress, setValuationInProgress] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusModalContent, setStatusModalContent] = useState({ type: '', description: '' });
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const queryClient = useQueryClient();

  useEffect(() => {
    const areArraysEqual = _.isEqual(optimalComparables, optimalComparablesResult);
    setIsResetDisabled(areArraysEqual);
  }, [optimalComparables, optimalComparablesResult]);

  const getPropertyCoordinates = async (address) => {
    try {
      const response = await getCoordinates({ query: address });
      const coordinates = {};
      if (response?.data?.data) {
        const [latitude, longitude] = response?.data?.data?.point.split(',');
        coordinates.latitude = Number(latitude);
        coordinates.longitude = Number(longitude);
      }
      return coordinates;
    } catch (error) {}
  };

  const revalueProperty = async () => {
    setValuationInProgress(true);
    const averagePrice =
      optimalComparables.reduce((total, item) => total + Number(item.price), 0) / optimalComparables.length;
    const comp_ids = optimalComparables.map((item) => item.id);
    try {
      const propertyParams = {
        uprn: property?.uprn,
        address: property?.address,
        property_type: property?.property_index_data?.property_type,
        num_rooms: Number(property?.property_index_data?.number_of_bedrooms),
        number_of_bedrooms: Number(property?.property_index_data?.number_of_bedrooms),
        area_code: property?.property_index_data?.area_code,
        district_code: property?.property_index_data?.district_code,
        post_code: property?.address?.split(' ').pop(),
        post_town: property?.property_index_data?.post_town,
        custom: true,
        latitude: property?.latitude,
        longitude: property?.longitude,
        epc: property?.epc,
        parent_id: property?.id,
        last_transaction_price: averagePrice,
        last_transaction_date: moment().format('YYYY-MM-DD'),
        user_entered_params: '2',
        comp_ids,
      };

      if (propertyParams.uprn.charAt(0) === '-' && !(propertyParams.latitude && propertyParams.longitude)) {
        const coordinates = await getPropertyCoordinates(propertyParams.address);
        Object.assign(propertyParams, coordinates);
      }
      const newPropertyResponse = await createProperty(propertyParams);
      if (newPropertyResponse) {
        queryClient.invalidateQueries(['rawProperties']);
        queryClient.invalidateQueries(['rawProperties', 'in-progress']);
        setStatusModalContent({
          show: true,
          type: 'success',
          description: `Valuation data for this property is now being generated.
          You'll receive an email notification when it's available.
          This process normally takes 1-2 minutes but may sometimes take a little longer.`,
          action: (
            <Link to={'/properties/'}>
              <PrimaryButton>View Properties</PrimaryButton>
            </Link>
          ),
        });
      }
    } catch (error) {
      setStatusModalContent({
        show: true,
        type: 'error',
        description: 'Error occurred, please try again later',
      });
    }
  };

  return (
    <>
      <StatusModal
        setShowModal={() => setStatusModalContent({ show: false })}
        showModal={statusModalContent.show}
        content={statusModalContent}
      />
      <PropertyGenerationStatusModal setShowModal={setShowStatusModal} showModal={showStatusModal} />

      <div className="my-8 flex w-full justify-between">
        <div>
          <h4 className="fs-16"> Favourite comparables </h4>
          <div className="text-xs">
            You can add up to 5 favourite comparables. Add or remove them from your list of favourites by clicking the
            heart icon.
          </div>
        </div>
        <div>
          <SecondaryButton
            className={'mr-2 ml-2 px-4 w-auto'}
            onClick={() => resetComparables()}
            disabled={isResetDisabled}
          >
            Reset Comparables
          </SecondaryButton>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5">
        {optimalComparables.map((comparable) => {
          return (
            <div key={comparable.id}>
              <PropertyCard property={comparable} toggleFavourite={toggleFavourite}></PropertyCard>
            </div>
          );
        })}
      </div>

      <div className="flex items-center justify-end mb-4">
        {valuationInProgress && <LoaderSvg className="animate-spin h-8 w-8 text-white" />}
        <PrimaryButton
          className={'mr-2 ml-2 px-5 w-auto '}
          onClick={() => revalueProperty()}
          disabled={!optimalComparables.length}
        >
          Generate valuation
        </PrimaryButton>
      </div>
    </>
  );
};

export default OptimisedCompsList;
