import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';
numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    borderBottomStyle: 'solid',
  },
  cell: {
    flex: 1,
    padding: 4,
    fontSize: 6,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
  },
  item: {
    width: '20%',
    padding: 5,
    border: '1 solid #f0f0f0',
  },
  address: {
    flex: 2,
    padding: 4,
    fontSize: 8,
  },

  imageWrapper: {
    width: '100%',
    height: 60,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});
const PdfOptimalComparablesSection = ({ optimalComparablesData }) => {
  return (
    <View style={styles.container}>
      {optimalComparablesData.map((row, index) => (
        <View key={index} style={styles.item}>
          {row.property_images && row.property_images[0] ? (
            <View style={styles.imageWrapper}>
              <Image src={`${row.property_images[0]}?a`} style={styles.image} />
            </View>
          ) : (
            <View style={styles.imageWrapper}>
              <Text style={styles.image}>No Image Available</Text>
            </View>
          )}

          <View style={styles.row}>
            <Text style={styles.address}>{row.address}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.cell}> Property Size (sq ft) </Text>
            <Text style={styles.cell}>{row.size ? row.size : '-'}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> Price (£) </Text>
            <Text style={styles.cell}>{row.price ? numeral(row.price).format() : '-'}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> Date </Text>
            <Text style={styles.cell}>{row.date ? row.date : '-'}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> Type </Text>
            <Text style={styles.cell}>
              {' '}
              {row.dataset === 'LS' ? 'Listing' : row.dataset === 'LR' ? 'Transaction' : '-'}{' '}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> New Build </Text>
            <Text style={styles.cell}>{row.old_new_lr ? (row.old_new_lr === 'N' ? 'No' : 'Yes') : '-'}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> Bathrooms </Text>
            <Text style={styles.cell}>{row.bathrooms ? parseInt(row.bathrooms) : '-'}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> Tenure </Text>
            <Text style={styles.cell}>
              {' '}
              {row.freehold_leasehold ? (row.freehold_leasehold === 'F' ? 'Freehold' : 'Leasehold') : '-'}{' '}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> EPC rating </Text>
            <Text style={styles.cell}>
              {row.current_energy_rating_epc && row.current_energy_rating_epc !== 'None'
                ? row.current_energy_rating_epc
                : '-'}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.cell}> Distance Radius </Text>
            <Text style={styles.cell}> {row.distance_from ? row.distance_from : '-'} </Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export default PdfOptimalComparablesSection;
